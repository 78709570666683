import React from 'react'
import { graphql } from 'gatsby'
import Markdown from 'react-markdown'
import Layout from '../components/layout-policy'
import SEO from '../components/seo'

export const query = graphql`
  query PolicyQuery {
    strapiGraphql {
      policy {
        Text
        pageSeo {
          title
          description
          keywords
        }
      }
    }
  }
`

export default function Policy({ data }) {
  const { Text: markdown, pageSeo } = data.strapiGraphql.policy
  return (
    <Layout>
      <SEO {...pageSeo} />
      <Markdown>{markdown}</Markdown>
    </Layout>
  )
}
