import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: #fff;
    font: 400 16px/30px Geometria, sans-serif;
    color: #1a1a1a;
    height: 100%;
    min-height: 100vh;

    * {
      box-sizing: border-box;
    }
  }
`

export const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
`
