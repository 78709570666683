import React from 'react'
import { GlobalStyles, StyledMain } from './styledComponents'
import Container from '../container'

const Layout = ({ children }) => {
  return (
    <Container>
      <GlobalStyles />
      <StyledMain>{children}</StyledMain>
    </Container>
  )
}

export default Layout
